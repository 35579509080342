<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">当前积分：{{ integral }}</div>   
        <div class="rb1" @click="goback">
          <img src="@/assets/fh.png" class="icon" />
          <div class="text">返回</div>
        </div>  
      </div>
      <div class="content">
        <div class="items">
          
          <div class="item">
            <div class="box qq">
              <div class="name">
                <span class="roomcode">{{ room_name }}</span>
                <span class="headline h1">{{ zone_name }}区</span>
                <span class="headline h1">{{ room_floor }}层</span>
              </div>
              <div class="name">
                <span class="headline h1">{{ checkin_date }}（入住）</span>
                <span class="headline h3">-</span>
                <span class="headline h1">{{ checkout_date }}（离店）</span>
                <span class="headline h2">{{ num_day }}天</span>
              </div>          
            </div>           
          </div>
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item" v-for="item in items" :key="item.id">
            <div class="box z1">
              <div class="name tt">
                <span class="roomcode f">{{ item.user_name }}</span>
              </div>
              <div class="name">
                <span class="region">{{ item.mobile }}</span>
              </div>
              <div class="name">
                <span class="region">{{ item.certificate_type }}</span>
              </div>
            </div>
            <div class="box z2">
              <div class="name">                              
                <div v-if="item.checkin_type === '0'" class="rb2">
                  <div class="text">扫</div>
                </div>
                <div v-if="item.checkin_type === '1'" class="rb2 xie">
                  <div class="text">协</div>
                </div>
              </div>              
            </div>
            <div class="box z3">
              <div class="name">                              
                <img :src="item.picture" class="imgpic" />
              </div>              
            </div>
            <!--<div class="box zz">
              <div class="name tt">
                <span class="roomcode f">{{ item.user_name }}</span>
              </div>
              <div class="name">
                <span class="region">手机号码：{{ item.mobile }}</span>
              </div>
            </div>
            <div class="box yy3">
              <div class="name">                              
                <div v-if="item.checkin_type==='0'" class="rb2">
                  <div class="text">扫</div>
                </div>
                <div v-if="item.checkin_type==='1'" class="rb2 xie">
                  <div class="text">协</div>
                </div>
              </div>              
            </div>
            <div class="box yy1">
              <div class="name">                              
                <img :src="item.imgstr" class="imgpic" />
              </div>              
            </div>-->            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
export default {
  created() {
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;
    this.IdNum = get(this.$route, 'query.IdNum');
    this.listIdNum = get(this.$route, 'query.listIdNum');
    this.dateSpace = this.$route.query.dateSpace;
    this.startdate = this.$route.query.startdate;
    this.enddate = this.$route.query.enddate;
    this.days = this.$route.query.days;
    this.room_code1 = this.$route.query.room_code1;
    this.room_code = get(this.$route, 'query.room_code');
    this.queryroom();
    this.query();

  },
  data() {
    return {
      integral: 0,
      openid: '',
      IdNum: '',
      listIdNum: '',
      room_code: '',
      days: '',
      startdate: '',
      enddate: '',
      dateSpace: '',
      room_code1: '',
      room_name: '',
      zone_name: '',
      room_floor: '',
      checkin_date: '',
      checkout_date: '',
      num_day: '',
      type: '',
      unionid: '',
      srole: true,
      items: [
        /*{name: '王**先生', mobile: '136****5612', state: '1', imgstr: '/img/nan.png'},
        {name: '罗**女士', mobile: '131****8633', state: '0', imgstr: '/img/nv.png'},
        {name: '李**先生', mobile: '133****8876', state: '0', imgstr: '/img/nan.png'},*/
      ]
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/barcounter/assist_room_state', query:{
        type:this.type,
        unionid:this.unionid,
        srole:this.srole,
        IdNum:this.IdNum,
        listIdNum:this.listIdNum,
        dateSpace:this.dateSpace,
        startdate:this.startdate,
        enddate:this.enddate,
        days:this.days,
        room_code:this.room_code1
      }});
    },
    async queryroom() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_selectroomtype',
        qs.stringify({
          room_code: this.room_code
        })
      );
      if (data.flag === '0') {
        /*this.room_name = data.data.room_name;
        this.zone_name = data.data.zone_name;
        this.room_floor = data.data.room_floor;    
        this.checkin_date = data.data.checkin_date;
        this.checkout_date = data.data.checkout_date;
        this.num_day = data.data.num_day; */ 
        
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 

        this.room_name = testobj.room_name;
        this.zone_name = testobj.zone_name;
        this.room_floor = testobj.room_floor;    
        this.checkin_date = testobj.checkin_date;
        this.checkout_date = testobj.checkout_date;
        this.num_day = testobj.num_day;
      }
    },
    async query() {
      let { data } = await this.axios.post('/ctid/TldjService/dj_selectroomuser', qs.stringify({ room_code: this.room_code }));
      //this.items = data.data;
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
    }
    
  }
};
</script>

<style lang="less" scoped>

.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 33px;
    float: left;
    margin-top: 10px;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.zz {
      width: 60%;
    }
    &.z1 {
      width: 50%;
    }
    &.z2 {
      width: 10%;
    }
    &.z3 {
      width: 40%;
    }
    &.yy {
      width: 15%;
    }
    &.yy2 {
      width: 10%;     
    }
    &.yy1 {
      width: 25%;
    }
    &.yy3 {
      width: 40%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      &.tt {
        margin-top: 32px;
      }
      .headline {
        font-size: 26px;
        color: rgb(168, 169, 170);     
        &.h1 {
          margin-right: 30px;
        }
        &.h2 {
          float: right;
        }
        &.h3 {
          margin-right: 35px;
        }
      }
      .imgpic {
        display: block;
        //width: 310px;  
        width: 160px;  
        height: 196px; 
        float: right;
      }
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
      .state {
        display: block;
        font-size: 26px;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
        &.xq {
          color: rgb(33, 18, 243);
        }
      }
      .floor {
        display: block;
        float: right;
        font-size: 26px;
        color: rgb(168, 169, 170);
        margin-right: 20px;
      }
      .roomcode {
        margin-right: 80px;
        &.f {
          font-size: 30px;
          color: rgb(168, 169, 170);
        }
      }
      .region {
        font-size: 26px;
        color: rgb(168, 169, 170);
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        float: left;
        width: 160px;
        border: 0;
        color: #333;
        &.qy {
          font-size: 30px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      .rb2 {
        height: 50px;
        width: 50px;
        float: right;
        overflow: hidden;
        background: rgb(130, 189, 217);
        margin-top: 70px;
        border-radius: 15px;
        &.xie {
          background: rgb(170, 149, 207);
        }
        .icon {
          width: 30px;
          height: 30px;
          margin: 14px 5px 14px 13px;
          display: block;
          float: left;
        }        
        .text {
          float: left;
          color: #fff;
          font-size: 30px;
          line-height: 50px;
          display: block;
          margin-left: 9px;
        }
      }
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 80px center no-repeat;
        background-size: 26px;
        width: 110px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color:  rgb(184, 188, 190);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
        &.ri {
          margin-right: 20px;
        }
      }
      
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
</style>
